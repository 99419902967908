<template>
  <v-container>
    <!-- -------------------검색------------- -->
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-container>
          <v-row align="center" justify="center">
            <v-col md="4" cols="12" align-self="center" class="text-center">
              <v-text-field
                placeholder="주소를 입력하여 주변 병원을 검색합니다."
                :value="this.form.address.value"
                background-color="#FFFFFF"
                rounded
                height="55px"
                readonly
                @click="searchAddress()"
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="8" align-self="center" class="text-center">
              <v-text-field
                placeholder="증상 입력"
                background-color="#FFFFFF"
                rounded
                height="55px"
                v-model="searchWord"
                v-on:keydown.enter.prevent="searchDoctor()"
              ></v-text-field>
            </v-col>
            <v-col md="2" cols="4" align-self="center" class="mb-2 text-center">
              <v-btn
                elevation="0"
                rounded
                dark
                color="reform_primary"
                height="55px"
                width="100%"
                :loading="loading"
                @click="searchDoctor()"
              >
                <span
                  v-if="$vuetify.breakpoint.lgAndUp"
                  class="search-font-size"
                >
                  이비인후과 검색
                </span>
                <span v-else class="search-font-size"> 검색 </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- ----------------------검색끝-------------- -->
    <!-- ------------------본문--------------- -->
    <v-row
      align="center"
      justify="center"
      v-for="(item, index) in hospital"
      :key="index"
      @click="click_list(item, index)"
      class="hospital-list"
    >
      <v-col>
        <v-card
          elevation="0"
          rounded="xl"
          max-width="100%"
          class="text-center align-start justify-center d-flex flex-column"
        >
          <v-list width="100%" class="card-list-wrap">
            <v-list-item>
              <v-list-item-content class="mr-10 list-item-wrap">
                <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
                  <v-avatar size="165">
                    <v-img
                      :src="
                        'https://www.drrefer.net/api/pic/' + item.drPicAttchId
                      "
                      v-if="
                        item.drPicAttchId !== null && item.drPicAttchId !== ''
                      "
                    >
                    </v-img>
                    <v-img
                      :src="
                        'https://www.drrefer.net/api/pic/' + item.logoAttchId
                      "
                      v-else-if="
                        item.logoAttchId !== null && item.logoAttchId !== ''
                      "
                    >
                    </v-img>
                    <v-img src="@/assets/images/logo_minimi.svg" v-else></v-img>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="12"
                  xl="9"
                  lg="9"
                  md="9"
                  sm="12"
                  xs="12"
                  class="items-col"
                >
                  <v-list
                    three-line
                    max-width="100%"
                    class="card-list-wrap-over"
                  >
                    <v-card
                      class="text-left justify-end mb-5 card-list-wrap-over"
                      elevation="0"
                    >
                      <!-- -----------맨윗줄--------------- -->
                      <v-list-item-title class="text-left">
                        <v-row
                          align="center"
                          justify="center"
                          no-gutters
                          class="items-list-row"
                        >
                          <v-col
                            cols="4"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="4"
                            xs="4"
                            class="font-weight-bold item-content-top item-list-col"
                            >{{ item.drNm }}</v-col
                          >
                          <v-col
                            cols="8"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="8"
                            xs="8"
                            class="reform_primary--text font-weight-bold item-content-top item-list-col"
                            >{{ item.hospNm }}</v-col
                          >
                          <v-col
                            cols="8"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="8"
                            xs="8"
                            class="reform_primary--text font-weight-bold item-content-middle-left item-list-col"
                          >
                            <v-icon> mdi-map-marker-outline </v-icon>

                            <span
                              v-if="item.addr2 !== null && item.addr2 !== ''"
                              >{{ item.addr2 }}</span
                            >
                          </v-col>
                          <v-col
                            cols="4"
                            xl="2"
                            lg="2"
                            md="2"
                            sm="4"
                            xs="4"
                            class="text-right item-content-middle-right"
                          >
                            <v-chip
                              v-if="showDist"
                              color="reform_primary_light"
                              class="font-weight-bold"
                            >
                              {{ getDistance(item.distance) }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                      <!-- -------------윗줄끝------------ -->
                      <!-- ------------------해시태그------------ -->
                      <v-list-item-subtitle
                        class="text-left reform_primary--text item-content-bottom items-list-dtl"
                        ><span
                          v-if="
                            item.dtlProObj !== null && item.dtlProObj !== ''
                          "
                          >#{{ item.dtlProObj }}
                        </span>
                        <span
                          v-if="
                            item.affilYn === 'Y' &&
                            item.affilCollage !== null &&
                            item.affilCollage !== ''
                          "
                          >#{{ item.affilCollage }}</span
                        >
                        <span
                          v-if="
                            item.gradYear !== null &&
                            item.gradYear !== '' &&
                            item.affilYn === 'Y'
                          "
                        >
                          #{{ item.gradYear }}
                        </span>
                        <span
                          v-if="
                            item.trainHosp !== null && item.trainHosp !== ''
                          "
                        >
                          #{{ item.trainHosp }}
                        </span>
                      </v-list-item-subtitle>
                      <!-- ----------------태그끝------------ -->
                    </v-card>
                    <!-- -------------------아랫줄---------------- -->
                    <v-card
                      class="text-left pa-0 ma-0 justify-end chip-wrap card-list-wrap-over"
                      elevation="0"
                    >
                      <v-row justify="end" class="items-list-row chip-row">
                        <v-col>
                          <v-chip
                            small
                            color="reform_primary"
                            outlined
                            class="mr-1 mb-1 font-weight-bold"
                            v-for="(chip, index) in getChips(item)"
                            :key="index"
                            style="font-size: 16px"
                          >
                            {{ chip }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row
                        justify="end"
                        class="items-list-row"
                        v-if="showDist"
                      >
                        <v-col cols="12" class="text-left hospital-note-col">
                          <v-btn
                            rounded
                            shaped
                            color="#146f83"
                            dark
                            width="200px"
                            height="50px"
                            elevation="0"
                            @click.stop="writeMessage(item)"
                            v-if="showDist"
                          >
                            <!-- <span class="material-icons-outlined">email</span> -->
                            <v-icon> mdi-email-outline </v-icon>
                            <span class="hospital-note">쪽지 보내기</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- ----------------아랫줄끝----------- -->
                  </v-list>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div
      class="list-empty"
      v-if="this.hospital.length < 1"
      style="background-color: white; border-radius: 30px; height: 250px"
    >
      <div class="spinner" v-if="isChildLoading">
        <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
          <beat-loader
            :loading="isChildLoading"
            :color="'#ff6565'"
            :size="'18px'"
          ></beat-loader>
        </transition>
      </div>
      <div class="item" v-if="!isChildLoading" style="text-align: center">
        <div class="logo-wrap" style="line-height: 230px">
          <img src="@/assets/images/logo_unplugged.svg" />
        </div>
        <h4 style="position: relative; top: -80px; color: #a2a2a2">
          조회결과 없음
        </h4>
      </div>
    </div>
    <!-- <div class="text-center">
      <v-pagination
        v-model="listStatus.currentPage"
        :length="listStatus.totalPage"
        circle
        class="pt-5"
        color="reform_primary"
        @input="movePage"
        @previous="navigatorAct()"
        @next="navigatorAct()"
      ></v-pagination>
    </div> -->
    <div class="pagination-wrap">
      <p
        class="navigation-act prev"
        v-if="listStatus.first > 5"
        @click="navigatorAct('prev')"
      >
        <!-- Prev -->
        <v-icon> mdi-chevron-left </v-icon>
      </p>
      <ul id="pagination">
        <li
          v-for="(pagination, index) in listStatus.currentPaginationList"
          :key="index + 'j'"
          @click="loadSerchList(pagination, index)"
          :class="
            (listStatus.currentPage - 1) % listStatus.pageCount === index
              ? 'active'
              : ''
          "
        >
          <p>{{ pagination }}</p>
        </li>
      </ul>
      <p
        class="navigation-act next"
        v-if="
          listStatus.next > 5 &&
          listStatus.pageCount < listStatus.totalPage &&
          listStatus.last !== listStatus.totalPage
        "
        @click="navigatorAct('next')"
      >
        <!-- Next -->
        <v-icon> mdi-chevron-right </v-icon>
      </p>
    </div>
    <!-- -------------------본문 끝-------------------- -->
    <address-popup
      @responseAddress="getAddress($event)"
      v-if="checkAddressPopup"
      :popupSetAddress="popupSetAddress"
    />
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import AddressPopup from "../modal/AddressPopup.vue";
import DefaultPopup from "../modal/DefaultPopup.vue";
import BeatLoader from "vue-spinner/src/BeatLoader";

export default {
  components: { AddressPopup, DefaultPopup, BeatLoader },
  data() {
    return {
      showDist: false,
      loader: {
        loading: false,
        color: "#ff6565",
        size: "18px",
      },
      hospital: [],
      saveData: "",
      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
      slides: ["main_bg_0.png"],
      selectedItem: 1,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      searchOpt: {
        addrType: 1, // distance 옵션
        orderType: 5, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
        searchTxt: "", // 검색어
        preSearchTxt: "", // 이전검색어 저장
        addr: null,
      },
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
      },
      form: {
        address: {
          addr: {},
          value: "",
          buildingname: {
            value: "",
            flag: false,
            hasError: false,
            errorTxt: "주소를 입력해주세요",
          },
          zonecode: {
            value: "",
          },
          bname: {
            value: "",
          },
          aim: {
            value: "",
          },
          locationPoint: {
            posX: 1,
            posY: 1,
          },
        },
      },
      searchWord: "",
      loader: null,
      loading: false,
      popupSet: {},
      isTopPriority: true,
      popupSetAddress: {},
    };
  },
  mounted() {
    this.loadLocalStorage();
    if (localStorage.getItem("token") === null) {
      this.showDist = false;
    } else {
      this.showDist = true;
    }
    this.searchWord = this.saveData.searchWord.substring(6);
    this.doSearch(
      this.saveData.searchWord,
      this.saveData.address.address + " " + this.saveData.address.buildingName
    );
  },
  computed: {
    ...mapState("basic", ["checkAddressPopup", "isChildLoading", "checkPopup"]),
    ...mapGetters("referList", ["GET_SEARCH_LIST"]),
    ...mapGetters("join", ["GET_BASIC"]),
    getDistance() {
      return (value) => {
        const calcuratedKM = Math.floor(value / 1000);
        const calcuratedM = Math.floor(value);
        if (calcuratedKM < 1) {
          return calcuratedM + "m";
        } else {
          return calcuratedKM + "km";
        }
      };
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    ...mapMutations("basic", [
      "SET_CHILD_LOADER",
      "SET_ADDRESS_POPUP",
      "SET_POPUP",
    ]),
    ...mapActions("referList", ["BRING_SEARCH_LIST"]),
    ...mapActions("join", ["FETCH_BASIC_ADDRESS"]),
    writeMessage(item) {
      //console.log(item);
      //this.$refs.appheader.callMessage(item);
      const sendAppheaderItem = {
        method: "callMessage",
        item: item,
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
    click_list(item, index) {
      window.open(
        "https://www.drrefer.net/dr/" + item.drId,
        "팝업",
        "width=1000px, height=1000px, scrollbars=yes, resizable=no"
      );
    },
    movePage(page) {
      this.listStatus.currentPage = page;
      this.loadSerchList(page, page - 1);
    },
    navigatorAct() {
      this.loadSerchList(
        this.listStatus.currentPage,
        this.listStatus.currentPage - 1
      );
    },
    getAvatarSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 80;
      } else return 120;
    },
    getChips(item) {
      let arr = [];
      if (item.diagNm1 !== null && item.diagNm1 !== "") arr.push(item.diagNm1);
      if (item.diagNm2 !== null && item.diagNm2 !== "") arr.push(item.diagNm2);
      if (item.diagNm3 !== null && item.diagNm3 !== "") arr.push(item.diagNm3);
      if (item.symptomNm1 !== null && item.symptomNm1 !== "")
        arr.push(item.symptomNm1);
      if (item.symptomNm2 !== null && item.symptomNm2 !== "")
        arr.push(item.symptomNm2);
      if (item.symptomNm3 !== null && item.symptomNm3 !== "")
        arr.push(item.symptomNm3);
      if (item.operNm1 !== null && item.operNm1 !== "") arr.push(item.operNm1);
      if (item.operNm2 !== null && item.operNm2 !== "") arr.push(item.operNm2);
      if (item.operNm3 !== null && item.operNm3 !== "") arr.push(item.operNm3);
      if (item.equipNm1 !== null && item.equipNm1 !== "")
        arr.push(item.equipNm1);
      if (item.equipNm2 !== null && item.equipNm2 !== "")
        arr.push(item.equipNm2);
      if (item.equipNm3 !== null && item.equipNm3 !== "")
        arr.push(item.equipNm3);
      return arr;
    },
    goDrRefer() {
      this.$router.push("/").catch(() => {});
    },
    goHome() {
      this.$router.push("/ent").catch(() => {});
    },
    async getAddress(address) {
      /*var addr = encodeURI(address.address,"utf-8");
      var api = "https://naveropenapi.apigw.ntruss.com/map-geocode/v2/geocode?query="+addr;
      console.log(addr + api)*/
      let result = null;
      await this.FETCH_BASIC_ADDRESS(address.address).then((data) => {
        result = data.result;
        // console.log(result);
        this.form.address.locationPoint.posX = data.posX;
        this.form.address.locationPoint.posY = data.posY;
        /** API 통신해서 받아온 문자열 */
      });
      if (result === false) {
        this.SET_POPUP(true);
        this.popupSet.title = "";
        this.popupSet.content = "위치정보를 받아오지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_ADDRESS_POPUP(false);
      } else {
        this.form.address.value = address.address;
        this.form.address.addr = address;
        this.form.address.buildingname.flag = true;
        this.form.address.buildingname.hasError = false;
        this.form.address.buildingname.value =
          address.address + " " + address.buildingName;
        this.form.address.zonecode.value = address.zonecode;
        this.form.address.bname.value = address.bname;
        this.form.address.aim.value = address.sido + " " + address.sigungu;
        this.SET_ADDRESS_POPUP(false);
        if (document.querySelector("body").classList.contains("spreaded")) {
          document.querySelector("body").classList.remove("spreaded");
        }

        this.searchOpt.addr = address.address + " " + address.buildingName;
      }
    },

    searchAddress() {
      this.SET_ADDRESS_POPUP(true);
      this.popupSet.popType = "address";
    },
    loadLocalStorage() {
      this.saveData = JSON.parse(window.localStorage.getItem("otoForm"));
    },
    doSearch(search, addr) {
      this.$nextTick((_) => {
        this.searchOpt.searchTxt = search;
        this.searchOpt.addr = addr;
        this.searchDoctor();
      });
    },
    async searchDoctor() {
      this.loader = "loading";

      if (this.searchWord !== null && this.searchWord !== "") {
        this.searchOpt.searchTxt = "이비인후과 " + this.searchWord;
      } else {
        if (
          this.saveData.searchWord !== null &&
          this.saveData.searchWord !== ""
        ) {
          this.searchOpt.searchTxt = this.saveData.searchWord;
        } else {
          this.searchOpt.searchTxt = "이비인후과";
        }
      }
      if (
        this.searchOpt.searchTxt !== null &&
        this.searchOpt.searchTxt !== ""
      ) {
        this.hospital = [];
        this.SET_CHILD_LOADER(true);
        let searchOption;
        // this.initPaginationEffect();
        this.listStatus.currentPage = 1;
        this.SET_CHILD_LOADER(false);
        try {
          if (localStorage.getItem("token") === null) {
            searchOption = {
              orderType: 1,
              addrType: 2,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: undefined,
              auth: false,
            };
          } else {
            searchOption = {
              orderType: this.searchOpt.orderType,
              addrType: 1,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: undefined,
              auth: true,
            };
          }
          // console.log(searchOption)
          await this.BRING_SEARCH_LIST(searchOption).then(() => {
            this.listStatus.totalDataCnt =
              this.GET_SEARCH_LIST.param.totalCount;
            this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
            // if (
            //   this.form.address.value !== null &&
            //   this.form.address.value !== undefined &&
            //   this.form.address.value !== ""
            // ) {
            //   this.showDist = true;
            // } else {
            //   this.showDist = false;

            // }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.hospital = this.GET_SEARCH_LIST.list;
              // console.log(this.GET_SEARCH_LIST.list);
              this.preParePagination();
            }, 100);
          });
        } catch (error) {
          console.log("error: ", error);
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
          }, 800);
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "검색어에 공백을 입력하실 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      // console.log("totalDataCnt", totalDataCnt);
      // console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      // console.log("totalPage", totalPage);
      // console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      // console.log("last : " + last);
      // console.log("first : " + first);
      // console.log("next :" + next);
      // console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
    },
    async calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          this.loadSerchList(this.listStatus.last, 4);
        });
        // this.$nextTick((_) => {
        //   this.loadSerchList(this.listStatus.last, 4);
        //   for (
        //     let k = 0;
        //     k < document.querySelector("#pagination").childElementCount;
        //     k++
        //   ) {
        //     // console.log(
        //     //   "재료",
        //     //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     //     .textContent
        //     // );
        //     if (
        //       Number(
        //         document.querySelector("#pagination").childNodes[k]
        //           .childNodes[0].textContent
        //       ) === Number(this.listStatus.last)
        //     ) {
        //       document
        //         .querySelector("#pagination")
        //         .childNodes[k].classList.add("active");
        //     }
        //   }
        // });
      } else if (type === "next") {
        this.loadSerchList(this.listStatus.currentPage, 0).then(() => {});
        // this.loadSerchList(this.listStatus.currentPage, 0).then(() => {
        //   document
        //     .querySelector("#pagination")
        //     .childNodes[0].classList.add("active");
        // });
      } else {
        if (this.hospital.length > 0) {
          await this.$nextTick((_) => {
            const target = document.querySelectorAll(".hospital-list");
            if (target !== undefined) {
              function addElsePagination(currentPage) {
                setTimeout(() => {
                  if (currentPage !== null) {
                    if (
                      document.querySelector("#pagination") === null ||
                      document.querySelector("#pagination") === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === null ||
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ].classList === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    // document
                    //   .querySelector("#pagination")
                    //   .childNodes[currentPage - 1].classList.add("active");
                  } else {
                    return addElsePagination(currentPage);
                  }
                }, 100);
              }
              addElsePagination(this.listStatus.currentPage);
            }
          });
        }
        // if (this.listStatus.totalDataCnt >= 1) {
        //   this.$nextTick((_) => {
        //     document
        //       .querySelector("#pagination")
        //       .childNodes[this.listStatus.currentPage - 1].classList.add(
        //         "active"
        //       );
        //   });
        // }
      }
    },
    // preParePagination() {
    //   const totalDataCnt = this.listStatus.totalDataCnt;
    //   const currentPage = this.listStatus.currentPage;
    //   const dataOnPage = this.listStatus.dataOnPage;
    //   const pageCount = this.listStatus.pageCount;
    //   const totalPage = Math.ceil(totalDataCnt / dataOnPage);
    //   const pageGroup = Math.ceil(currentPage / pageCount);
    //   let last = pageGroup * pageCount;
    //   if (last > totalPage) {
    //     last = totalPage;
    //   }
    //   let first = last - (pageCount - 1);
    //   const next = last + 1;
    //   const prev = first - 1;
    //   if (totalPage < 1) {
    //     first = last;
    //   }
    //   this.listStatus.totalPage = totalPage;
    //   this.listStatus.prev = prev;
    //   this.listStatus.next = next;
    //   this.listStatus.first = first;
    //   this.listStatus.last = last;
    //   this.calculatedPaginationList(first, last);
    // },
    // calculatedPaginationList(first, last, type) {
    //   const paginationListArray = [];
    //   for (let j = first; j <= last; j++) {
    //     if (j > 0) {
    //       paginationListArray.push(j);
    //     }
    //   }
    //   this.listStatus.currentPaginationList = paginationListArray;
    //   if (type === "prev") {
    //     this.$nextTick((_) => {
    //       this.loadSerchList(this.listStatus.last, 4);
    //     });
    //   } else if (type === "next") {
    //     this.loadSerchList(this.listStatus.currentPage, 0);
    //   }
    // },
    // async loadSerchList(currentPage, order) {
    //   //console.log(currentPage, order);
    //   this.hospital = [];
    //   this.SET_CHILD_LOADER(true);
    //   let searchOption;
    //   try {
    //     if (
    //       this.searchOpt.addr !== null &&
    //       this.searchOpt.addr !== undefined &&
    //       this.searchOpt.addr !== "undefined"
    //     ) {

    //       searchOption = {
    //         orderType: 1,
    //         addrType: 2,
    //         searchWord: this.searchOpt.searchTxt,
    //         recordsPerPage: this.listStatus.dataOnPage,
    //         pageNo: currentPage,
    //         addr: this.searchOpt.addr,
    //         auth: false,
    //       };
    //     } else {
    //       searchOption = {
    //         orderType: this.searchOpt.orderType,
    //         addrType: 2,
    //         searchWord: this.searchOpt.searchTxt,
    //         recordsPerPage: this.listStatus.dataOnPage,
    //         pageNo: currentPage,
    //         auth: false,
    //       };
    //     }
    //     await this.BRING_SEARCH_LIST(searchOption).then(() => {
    //       // console.log("가져온 결과값", this.GET_SEARCH_LIST);
    //       this.listStatus.totalDataCnt = this.GET_SEARCH_LIST.param.totalCount;
    //       /** this.listStatus.currentPage = this.GET_SEARCH_LIST.param.pageNo;*/
    //       /** this.listStatus.dataOnPage = this.GET_SEARCH_LIST.param.recordsPerPage; */
    //       this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
    //       setTimeout(() => {
    //         this.SET_CHILD_LOADER(false);
    //         this.hospital = this.GET_SEARCH_LIST.list;
    //         this.listStatus.currentPage = order + 1;
    //       }, 800);
    //     });
    //   } catch (error) {
    //     console.log("error: ", error);
    //     setTimeout(() => {
    //       this.SET_CHILD_LOADER(false);
    //     }, 800);
    //   }
    // },
    async loadSerchList(currentPage, order) {
      // console.log(currentPage);
      this.hospital = [];
      this.SET_CHILD_LOADER(true);
      // this.initPaginationEffect();
      // if (this.listStatus.totalDataCnt >= 1) {
      //   document
      //     .querySelector("#pagination")
      //     .childNodes[order].classList.add("active");
      // }
      let searchOption;
      try {
        if (
          this.searchOpt.addr !== null &&
          this.searchOpt.addr !== "undefined undefined"
        ) {
          searchOption = {
            orderType: 1,
            addrType: 2,
            searchWord: this.searchOpt.searchTxt,
            recordsPerPage: this.listStatus.dataOnPage,
            pageNo: currentPage,
            addr: this.searchOpt.addr,
            auth: false,
          };
        } else {
          searchOption = {
            orderType: this.searchOpt.orderType,
            addrType: 2,
            searchWord: this.searchOpt.searchTxt,
            recordsPerPage: this.listStatus.dataOnPage,
            pageNo: currentPage,
            auth: false,
          };
        }
        await this.BRING_SEARCH_LIST(searchOption).then(() => {
          // console.log("가져온 결과값", this.GET_SEARCH_LIST);
          this.listStatus.totalDataCnt = this.GET_SEARCH_LIST.param.totalCount;
          /** this.listStatus.currentPage = this.GET_SEARCH_LIST.param.pageNo;*/
          /** this.listStatus.dataOnPage = this.GET_SEARCH_LIST.param.recordsPerPage; */
          this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.hospital = this.GET_SEARCH_LIST.list;
            this.listStatus.currentPage = order + 1;
          }, 100);
        });
      } catch (error) {
        console.log("error: ", error);
        setTimeout(() => {
          this.SET_CHILD_LOADER(false);
        }, 800);
      }
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        // console.log(
        //   "Ds",
        //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     .textContent
        // );
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "viewport",
          name: "viewport",
          content:
            "width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/EntListComponent";
</style>
