<template>
  <v-container fluid style="background-color: #f5f9fa">
    <v-row justify="center">
      <v-col cols="12" xl="8">
        <ent-list-component 
          @send-app-header-method="sendAppheaderMethod"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EntListComponent from "@/components/ent/EntListComponent.vue";
export default {
  components: { EntListComponent },
  mounted () {
    const sendAppheaderItem = {
        method: "entHeader",
        item: "on",
      };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  methods: {
    sendAppheaderMethod(sendAppheaderItem) {
      this.$emit("send-app-header-method", sendAppheaderItem);
    }
  },
  destroyed () {
    const sendAppheaderItem = {
      method: "entHeader",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style></style>
